import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from '@hiyllo/ux/theme';
import React from 'react';

interface PropsType {
  color?: string
  spinIcon?: boolean
  text: string
  isLoading?: boolean
  onClick?: () => void
}

export const Button = (props: PropsType): JSX.Element => {
  const theme = useTheme();
  return (
    <div style={{
      display: 'inline-flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: 10,
      padding: 15,
      lineHeight: '1em',
      borderRadius: 10,
      color: props.color,
      cursor: 'pointer',
      position: 'relative',
      background: theme.buttonBackground
    }} onClick={props.onClick}>
      <div style={{ color: props.isLoading === true ? 'transparent' : props.color }}>
        {props.text}
      </div>
      {props.isLoading === true
        ? <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <FontAwesomeIcon icon={faSpinnerThird} spin/>
        </div>
        : null}
    </div>
  );
};
