/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { styled } from '@hiyllo/ux/styled';
import { motion, useScroll, useTransform } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments, faEnvelope, faQuestionCircle, faSearch, faSitemap, faCalendar, faSquareKanban, faVideoCamera, faFolder, faRocket } from '@fortawesome/pro-light-svg-icons';
import { Button } from '@hiyllo/ux/button';
import { LoadingSpinner } from '@hiyllo/ux/loading-spinner';
import moment from 'moment';
import { FakeChatWindow } from './fake-chat-window';
import { faArrowPointer } from '@fortawesome/pro-solid-svg-icons';
import { EffortlesslyConnected } from './effortlessly-connected';
import { ScrollApp } from './scroll-app';

const RootPage = styled('div', ({ $theme }) => ({
  background: $theme.background1,
  color: $theme.foreground,
  // height: '100vh',
  width: '100vw',
  overflowX: 'hidden',
  // overflow: 'hidden',
  scrollSnapType: 'y mandatory'
  // overflowY: 'auto',
}));

const IS_MOBILE = window.innerHeight > window.innerWidth;

const FullPageCentered = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  [IS_MOBILE ? 'minHeight' : 'height']: '100vh',
  width: '100vw',
  overflowX: 'hidden'
});

const ScreenshotImage = styled('img', ({ $theme }) => ({
  boxShadow: 'rgba(0,0,0,0.75) 0px 0px 50px -5px',
  borderRadius: 5
}));

const JumboText = styled('div', ({ $theme }) => ({
  fontSize: IS_MOBILE ? 42 : 48
  // marginBlock: 10
}));

const SnapSection = styled('div', {
  scrollSnapAlign: 'start'
});

const SplashPhotos = React.memo(function SplashPhotos(): JSX.Element {
  return (
    <div style={{ position: IS_MOBILE ? undefined : 'relative' }}>
      <motion.div
        initial={{ opacity: 0, translateY: 200 }}
        animate={{ opacity: 1, translateY: 0 }}
        transition={{ duration: 2, delay: 1 }}
        style={IS_MOBILE ? { position: 'absolute', top: IS_MOBILE ? '18vh' : '20vh', left: 0, width: '100vw', textAlign: 'center' } : { position: 'absolute', top: '-40%', width: '100%', textAlign: 'center' }}
      >
        <JumboText>
          <GradientText>
            Altogether. <b>In One Place.</b>
          </GradientText>
        </JumboText>
        <div>
          <div style={{ display: 'inline-block', padding: 10, borderRadius: 5 }}>
            <span style={{ opacity: 0.5 }}>The All-Encompassing</span> Team Workspace
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', gap: 10, alignItems: 'center', justifyContent: 'center' }}>
          <FontAwesomeIcon icon={faComments} fixedWidth/>
          <FontAwesomeIcon icon={faSquareKanban} fixedWidth/>
          <FontAwesomeIcon icon={faCalendar} fixedWidth/>
          <FontAwesomeIcon icon={faEnvelope} fixedWidth/>
          <FontAwesomeIcon icon={faFolder} fixedWidth/>
        </div>
      </motion.div>
      <motion.div
        initial={{
          opacity: 0,
          transform: 'rotateY(40deg) rotateZ(10deg)'
        }}
        animate={{
          opacity: 1,
          transform: 'rotateY(0deg) rotateZ(0deg)'
        }}
        transition={{ duration: 1, delay: 0.2 }}
        style={IS_MOBILE ? { position: 'absolute', left: '10%', top: '50%' } : {}}
      >
        <ScreenshotImage src="/ss/chat-with-last-read.png" style={IS_MOBILE ? { width: '75vw', height: 'auto' } : { height: '100%', width: 'auto', maxHeight: '40vh', maxWidth: '40vw' }}/>
      </motion.div>
      <motion.div
        initial={{ opacity: 0, transform: 'rotateY(10deg) rotateZ(-2deg)' }}
        animate={{ opacity: 1, transform: 'rotateY(0deg) rotateZ(0deg)' }}
        transition={{ duration: 1, delay: 0.2 }}
        style={IS_MOBILE ? { position: 'absolute', top: '38%', left: '57.5%' } : { position: 'absolute', top: '70%', left: '80%' }}
      >
        <ScreenshotImage src="/ss/calendar.png" style={IS_MOBILE ? { height: '20vh', width: 'auto' } : { width: 'auto', height: '30vh' }}/>
      </motion.div>
      <motion.div
        initial={{ opacity: 0, transform: 'rotateY(10deg) rotateZ(-2deg)' }}
        animate={{ opacity: 1, transform: 'rotateY(0deg) rotateZ(0deg)' }}
        transition={{ duration: 1, delay: 0.2 }}
        style={IS_MOBILE ? { position: 'absolute', top: '65%', right: '50%' } : { position: 'absolute', top: '50%', right: '80%' }}
      >
        <ScreenshotImage src="/ss/kanban-project.png" style={{ width: 'auto', height: '27vh' }}/>
      </motion.div>
    </div>
  );
});

const ChatBubble = styled('div', ({ $theme }) => ({
  background: '#d0d0f8',
  color: '#333',
  padding: 15,
  borderRadius: 10,
  display: 'inline-block'
}));

const Altogether = React.memo(function Altogether(): JSX.Element {
  const ref = React.useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['start end', 'end start']
  });
  const translateX = useTransform(scrollYProgress, [0, 0.5], ['-100%', '0%']);

  return (
    <div>
      <FullPageCentered>
        <div style={{ padding: 100 }} ref={ref}>
          <div />
          <motion.div
            style={{
              fontSize: 24,
              translateX
            }}
          >
            <ChatBubble>
              <div style={{ marginBottom: '-0.15em' }}>
                <span style={{ opacity: 0.45, textDecoration: 'line-through' }}>Why do we have ten thousand <span style={{ filter: 'blur(2px)' }}>%&$*</span>ing folders</span>
                &nbsp;Hey, what folder is the pitch deck in again?
              </div>
            </ChatBubble>
          </motion.div>
          <div style={{ height: 20 }}/>
          <motion.div
            style={{
              fontSize: 24,
              translateX
            }}
            transition={{ delay: 0.2 }}
          >
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
            <ChatBubble>
              <div style={{ marginBottom: '-0.15em' }}>
              <span style={{ opacity: 0.45, textDecoration: 'line-through' }}>How many times do I have to ask you?</span>
                &nbsp;Did you submit the financials? I can&apos;t find them.
              </div>
            </ChatBubble>
            </div>
          </motion.div>
          <div style={{ height: 20 }}/>
          <motion.div
            style={{
              fontSize: 24,
              translateX
            }}
            transition={{ delay: 0.4 }}
          >
            <ChatBubble>
              <div style={{ marginBottom: '-0.15em' }}>
                <span style={{ opacity: 0.45, textDecoration: 'line-through' }}>Why on earth is this thing making me sign in AGAIN?</span>
                &nbsp;Can you create the meeting? It&apos;s not working for me.
              </div>
            </ChatBubble>
          </motion.div>
          <div style={{ height: 20 }}/>
          <motion.div
            style={{
              fontSize: 24,
              translateX
            }}
            transition={{ delay: 0.4 }}
          >
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
              <ChatBubble>
                <div style={{ marginBottom: '-0.15em' }}>
                  <span style={{ opacity: 0.45, textDecoration: 'line-through' }}>We literally pay for this why is it such <span style={{ filter: 'blur(2px)' }}>%&$*</span></span>
                  &nbsp;Does my audio sound weird?
                </div>
              </ChatBubble>
            </div>
          </motion.div>
          <div style={{ height: 20 }}/>
          <motion.div
            style={{
              fontSize: 24,
              translateX
            }}
          >
            <ChatBubble>
              <div style={{ marginBottom: '-0.15em' }}>
              <span style={{ opacity: 0.45, textDecoration: 'line-through' }}>Why can you never remember to share the files with me</span>
                &nbsp;Hey, I can&apos;t access the file?
              </div>
            </ChatBubble>
          </motion.div>
          <div style={{ height: 20 }}/>
          <motion.div
            style={{
              fontSize: 24,
              translateX
            }}
            transition={{ delay: 0.4 }}
          >
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
              <ChatBubble>
                <div style={{ marginBottom: '-0.15em' }}>
                  <span style={{ opacity: 0.45, textDecoration: 'line-through' }}>Every. Single. Morning. 100. Emails. AND WHY ARE THEY ALL PEOPLE MOVING AROUND TICKETS</span>
                  &nbsp;👍
                </div>
              </ChatBubble>
            </div>
          </motion.div>
          <div style={{ height: 40 }}/>
          <motion.div style={{ textAlign: 'center', translateY: translateX }}>
            <JumboText>
              Disconnected and decentralized tools are<br/><b>draining your team&apos;s efficiency.&nbsp;</b>
            </JumboText>
          </motion.div>
        </div>
      </FullPageCentered>
    </div>
  );
});

const makeTwo = (n: number): string => {
  const num = Math.floor(n);

  if (num.toString().length > 2) {
    return num.toString().slice(0, 2);
  }

  if (num < 10) {
    return `0${num}`;
  }

  return num.toString();
};

const ImageLeftHalfCard = React.memo(function ImageHalfCard(props: {
  imageSrc: string;
  content: JSX.Element
}): JSX.Element {
  const ref = React.useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['start end', 'end start']
  });
  const opacity = useTransform(scrollYProgress, [0, 0.5], [0, 1]);
  const imageTranslateX = useTransform(scrollYProgress, [0, 0.5], [-300, 0]);
  const contentTranslateX = useTransform(scrollYProgress, [0, 0.5], [300, 0]);
  const contentTranslateY = useTransform(scrollYProgress, [0, 0.5], [300, 0]);

  return (
    <div ref={ref} style={{ marginBottom: IS_MOBILE ? 80 : 0, overflow: 'hidden', gap: 40, height: IS_MOBILE ? '' : '100vh', display: 'flex', flexDirection: IS_MOBILE ? 'column' : 'row', width: '100%', alignItems: 'center', flexWrap: 'wrap' }}>
      <motion.div
        style={{
          boxShadow: 'rgba(0,0,0,0.75) 0px 0px 50px -5px',
          marginLeft: 40,
          borderRadius: 10,
          overflow: 'hidden',
          opacity,
          translateX: imageTranslateX
        }}
      >
        <img
          src={props.imageSrc}
          style={{
            width: IS_MOBILE ? '100vw' : '60vw',
            height: 'auto',
            borderRadius: 5
          }}
        />
      </motion.div>
      <motion.div style={{ width: IS_MOBILE ? '100%' : 0, flexGrow: 1, translateX: contentTranslateX, translateY: contentTranslateY }}>
        {props.content}
      </motion.div>
    </div>
  );
});

const ImageRightHalfCardActual = React.memo(function ImageHalfCard(props: {
  imageSrc: string;
  content: JSX.Element
}): JSX.Element {
  const ref = React.useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['start end', 'end start']
  });
  const opacity = useTransform(scrollYProgress, [0, 0.5], [0, 1]);
  const imageTranslateX = useTransform(scrollYProgress, [0, 0.5], [300, 0]);
  const contentTranslateX = useTransform(scrollYProgress, [0, 0.5], [-300, 0]);
  const contentTranslateY = useTransform(scrollYProgress, [0, 0.5], [-300, 0]);

  return (
    <div ref={ref} style={{ overflow: 'hidden', gap: 40, height: IS_MOBILE ? '' : '100vh', display: 'flex', flexDirection: IS_MOBILE ? 'column' : 'row', width: '100%', alignItems: 'center' }}>
      <motion.div style={{ width: 0, flexGrow: 1, paddingLeft: 40, translateX: contentTranslateX, translateY: contentTranslateY }}>
        {props.content}
      </motion.div>
      <motion.div
        style={{
          boxShadow: 'rgba(0,0,0,0.75) 0px 0px 50px -5px',
          marginRight: 40,
          borderRadius: 10,
          overflow: 'hidden',
          opacity,
          translateX: imageTranslateX
        }}
      >
        <img
          src={props.imageSrc}
          style={{
            width: '60vw',
            height: 'auto',
            borderRadius: 5
          }}
        />
      </motion.div>
    </div>
  );
});

const ImageRightHalfCard = IS_MOBILE ? ImageLeftHalfCard : ImageRightHalfCardActual;

function App(): JSX.Element {
  const { scrollY } = useScroll({
    target: { current: window.document.body },
    offset: ['start end', 'end start']
  });
  const whiteIconOpacity = useTransform(scrollY, [0, window.innerHeight], [1, 0]);
  const colorIconOpacity = useTransform(scrollY, [0, window.innerHeight], [0, 1]);
  // if(new Date().valueOf() < LAUNCH) {
  //   return (
  //     <FullPageCentered>
  //       <Countdown/>
  //     </FullPageCentered>
  //   )
  // }

  // if(window.innerHeight > window.innerWidth) {
  //   return (
  //     <FullPageCentered style={{color:"white"}}>
  //       <div style={{textAlign:"center", display: "flex", flexDirection:"column", alignItems:"center"}}>
  //         <JumboText style={{fontSize: 32}}>Hey there! 👋</JumboText>
  //         <div style={{maxWidth:"75%"}}>
  //           <JumboText style={{fontSize: 16}}>Our mobile site isn't quite ready yet, sorry! We&apos;re still experimenting with the best way to show you our vision on a phone.</JumboText>
  //           <JumboText style={{fontSize: 16}}>Could you check us out on your desktop?</JumboText>
  //         </div>
  //       </div>
  //       <div style={{position:"absolute", bottom:20, left: 20}}>
  //         <img src="/work-logo.png" style={{height:40, width:"auto"}}/>
  //       </div>
  //     </FullPageCentered>
  //   )
  // }

  return (
    <RootPage>
      <motion.div
        style={{
          position: 'fixed',
          height: '100%',
          width: '100%',
          pointerEvents: 'none',
          top: 0,
          left: 0,
          background: 'black',
          zIndex: 1000,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        initial={{ opacity: 1 }}
        animate={{ opacity: 0 }}
        transition={{ duration: 0.5, delay: 0.8 }}
      >
        <LoadingSpinner/>
      </motion.div>
      <div style={{ position: 'fixed', zIndex: 100, backdropFilter: 'blur(20px)', top: 0, left: 0, height: 90, width: IS_MOBILE ? 'calc(100% - 30px)' : 'calc(100% - 60px)', paddingLeft: 30, paddingRight: IS_MOBILE ? 0 : 30, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
        <div style={{ position: 'relative', width: IS_MOBILE ? 20 : 40, height: IS_MOBILE ? 20 : 40 }}>
          <motion.img src="https://cdn.hiyllo.net/logo/work/icon-white.png" style={{ height: IS_MOBILE ? 20 : 40, width: 'auto', opacity: whiteIconOpacity }}/>
          <motion.img src="/work-logo.png" style={{ height: IS_MOBILE ? 20 : 40, width: 'auto', opacity: colorIconOpacity, position: 'absolute', top: 0, left: 0 }}/>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10 }}>
          <div onClick={() => {
            document.scrollingElement?.scrollTo(0, document.body.scrollHeight);
          }} style={{ cursor: 'pointer', paddingLeft: 20, paddingRight: 20 }}>
            Pricing
          </div>
          <a href="mailto:sales@hiyllo.io" style={{ textDecoration: 'none' }}>
            <div style={{ color: 'white', textDecoration: 'none', paddingLeft: 20, paddingRight: 20, userSelect: 'none' }}>
              <FontAwesomeIcon icon={faEnvelope} fixedWidth/>&nbsp;<span style={{ textDecoration: 'none', userSelect: 'auto' }}>sales@hiyllo.io</span>
            </div>
          </a>
          {!IS_MOBILE ?
            <Button
              style={{ fontFamily: 'sans-serif', fontWeight: 'bold' }}
              label="Try it free for 3 weeks"
              onClick={() => {
                window.parent.postMessage('login', '*');
              }}
            />
          : null}
        </div>
      </div>
      {IS_MOBILE ?
        <SnapSection>
          <FullPageCentered style={{ position: 'relative' }}>
            <SplashPhotos/>
          </FullPageCentered>
        </SnapSection>
      :
        <SnapSection>
          <div style={{ height: '99vh', paddingTop: 70, paddingLeft: 25, display: 'flex', flexDirection: 'row' }}>
            <div style={{ height: '100%', width: '60vw' }}>
              <div style={{ fontSize: '17.5vh' }}>
                <b>
                  Everything your team needs...
                </b>
                {/* <b>
                  What your team uses every day:
                </b> */}
                <br/>
                <GradientText>
                  <b>
                    In one place.
                  </b>
                </GradientText>
                <div>
                  <div style={{ display: 'flex', flexDirection: 'row', gap: 10, alignItems: 'center', justifyContent: 'flex-start', fontSize: 40, marginTop: 15 }}>
                    <FontAwesomeIcon icon={faComments} fixedWidth/>
                    <FontAwesomeIcon icon={faSquareKanban} fixedWidth/>
                    <FontAwesomeIcon icon={faCalendar} fixedWidth/>
                    <FontAwesomeIcon icon={faEnvelope} fixedWidth/>
                    <FontAwesomeIcon icon={faFolder} fixedWidth/>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ width: '40vw', position: 'relative' }}>
              <motion.div
                initial={{
                  opacity: 0,
                  transform: 'rotateY(40deg) rotateZ(10deg)'
                }}
                animate={{
                  opacity: 1,
                  transform: 'rotateY(0deg) rotateZ(0deg)'
                }}
                transition={{ duration: 1, delay: 1.2 }}
                style={{ position: 'absolute', left: '0%', top: '2.5%' }}
              >
                <FakeChatWindow/>
              </motion.div>
              <motion.div
                initial={{
                  opacity: 0,
                  transform: 'rotateY(40deg) rotateZ(10deg)'
                }}
                animate={{
                  opacity: 1,
                  transform: 'rotateY(0deg) rotateZ(0deg)'
                }}
                transition={{ duration: 1, delay: 1.2 }}
                style={{ position: 'absolute', left: '27.5%', top: '32.5%' }}
              >
                <ScreenshotImage src="/ss/chat-with-last-read.png" style={{ height: '30vh', width: 'auto', maxHeight: '40vh', maxWidth: '40vw' }}/>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, transform: 'rotateY(10deg) rotateZ(-2deg)' }}
                animate={{ opacity: 1, transform: 'rotateY(0deg) rotateZ(0deg)' }}
                transition={{ duration: 1, delay: 1.3 }}
                style={{ position: 'absolute', top: '90%', left: '20%' }}
              >
                <ScreenshotImage src="/ss/calendar.png" style={IS_MOBILE ? { height: '20vh', width: 'auto' } : { width: 'auto', height: '30vh' }}/>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, transform: 'rotateY(10deg) rotateZ(-2deg)' }}
                animate={{ opacity: 1, transform: 'rotateY(0deg) rotateZ(0deg)' }}
                transition={{ duration: 1, delay: 1.4 }}
                style={{ position: 'absolute', top: '62.5%', left: '-15%' }}
              >
                <ScreenshotImage src="/ss/kanban-project.png" style={{ width: 'auto', height: '30vh' }}/>
              </motion.div>
            </div>
          </div>
        </SnapSection>
      }
      {!IS_MOBILE ?
        <div style={{ fontSize: '17.5vh', padding: 20 }}>
          <b>
            Chat.<br/>
            <div style={{ height: 30 }}/>
            Project<br/>Management.<br/>
            <div style={{ height: 30 }}/>
            Calendar.<br/>
            <div style={{ height: 30 }}/>
            Email.<br/>
            <div style={{ height: 30 }}/>
            File Storage.<br/>
            <div style={{ height: 30 }}/>
            <GradientText>
              Hiyllo Work.
            </GradientText>
          </b>
        </div>
      : null}
      <SnapSection>
        <EffortlesslyConnected/>
      </SnapSection>
      <SnapSection>
        <ScrollApp/>
      </SnapSection>
      {/* <SnapSection>
        <Altogether/>
      </SnapSection> */}
      {/* <SnapSection>
        <FullPageCentered>
          <div style={{ maxWidth: '75%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 1, delay: 1.5 }}
              viewport={{ once: true }}
            >
            <JumboText style={{ textAlign: 'center' }}>
              We&apos;re on a mission to piece your company together.
            </JumboText>
            </motion.div>
            <div style={{ height: 40 }}/>
            <div style={{ position: 'relative' }}>
              <img src="/work-icon.png" style={{ height: 200, width: 200, zIndex: 10 }}/>
              <motion.div
                style={{ position: 'absolute', top: 0, left: 0, height: 200, width: 200, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                initial={{ transform: 'translateX(-40vw) translateY(10vh)', opacity: 1 }}
                whileInView={{ transform: 'translateX(0%) translateY(0%)', opacity: 0 }}
                transition={{ duration: 1, delay: 0.75, ease: 'easeIn' }}
                viewport={{ once: true }}
              >
                <FontAwesomeIcon icon={faComments} style={{ fontSize: 100 }}/>
              </motion.div>
              <motion.div
                style={{ position: 'absolute', top: 0, left: 0, height: 200, width: 200, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                initial={{ transform: 'translateX(20vw) translateY(-30vh)', opacity: 1 }}
                whileInView={{ transform: 'translateX(0%) translateY(0%)', opacity: 0 }}
                transition={{ duration: 1, delay: 0.5, ease: 'easeIn' }}
                viewport={{ once: true }}
              >
                <FontAwesomeIcon icon={faSquareKanban} style={{ fontSize: 100 }}/>
              </motion.div>
              <motion.div
                style={{ position: 'absolute', top: 0, left: 0, height: 200, width: 200, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                initial={{ transform: 'translateX(30vw) translateY(30vh)', opacity: 1 }}
                whileInView={{ transform: 'translateX(0%) translateY(0%)', opacity: 0 }}
                transition={{ duration: 1, delay: 0.5, ease: 'easeIn' }}
                viewport={{ once: true }}
              >
                <FontAwesomeIcon icon={faVideoCamera} style={{ fontSize: 100 }}/>
              </motion.div>
              <motion.div
                style={{ position: 'absolute', top: 0, left: 0, height: 200, width: 200, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                initial={{ transform: 'translateX(-30vw) translateY(-32vh)', opacity: 1 }}
                whileInView={{ transform: 'translateX(0%) translateY(0%)', opacity: 0 }}
                transition={{ duration: 1, delay: 0.5, ease: 'easeIn' }}
                viewport={{ once: true }}
              >
                <FontAwesomeIcon icon={faCalendar} style={{ fontSize: 100 }}/>
              </motion.div>
            </div>
          </div>
        </FullPageCentered>
      </SnapSection> */}
      <SnapSection>
        <ImageLeftHalfCard
          imageSrc="/ss/chat-with-last-read.png"
          content={
            <>
              <JumboText>Chat</JumboText>
              <div style={{ lineHeight: '1.2em', fontSize: 18, maxWidth: '75%' }}>All the bells and whistles, from reactions and pinned messages, to <GradientText>AI-generated summaries</GradientText></div>
            </>
          }
        />
      </SnapSection>
      <SnapSection>
        <ImageRightHalfCard
          imageSrc="/ss/kanban-project.png"
          content={
            <>
              <JumboText>Task Management</JumboText>
              <div style={{ lineHeight: '1.2em', fontSize: 18, maxWidth: '75%' }}>Easy to grasp for individual contributors, with powerful features for project managers and leadership</div>
            </>
          }
        />
      </SnapSection>
      <SnapSection>
        <ImageLeftHalfCard
          imageSrc="/ss/calendar.png"
          content={
            <>
              <JumboText>Calendar</JumboText>
              <div style={{ lineHeight: '1.2em', fontSize: 18, maxWidth: '75%' }}>
                Intuitively create events, schedule meetings, and mark busy times. Team members can use <img src="https://solo.hiyllo.io/favicon.png" style={{ height: '0.7em', width: 'auto' }}/> Hiyllo Solo for free to sync with their personal schedules, including Google Calendar and Microsoft Outlook, without exposing company data.
              </div>
            </>
          }
        />
      </SnapSection>
      <SnapSection>
        <FullPageCentered>
          <div>
            <JumboText style={{ textAlign: 'center' }}>
              <GradientText style={{ display: 'inline-block' }}>
                <span>
                  Not to mention:
                </span>
              </GradientText>
            </JumboText>
            <div style={{ height: 40 }}/>
            <div style={{ paddingBottom: IS_MOBILE ? 40 : 0, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'stretch', gap: 20, flexWrap: 'wrap' }}>
              <Card style={{ width: 'min(300px, 60vw)' }}>
                <FontAwesomeIcon icon={faVideoCamera} style={{ fontSize: 100, marginBottom: 10 }}/>
                <div style={{ lineHeight: '1.2em' }}>Crystal-Clear Video Meetings, even when connecting with people on the other side of the planet</div>
              </Card>
              <Card style={{ width: 'min(300px, 60vw)' }}>
                <FontAwesomeIcon icon={faSitemap} style={{ fontSize: 100, marginBottom: 10 }}/>
                <div style={{ lineHeight: '1.2em' }}>Full organizational understanding, with interactive org-charts and teams across the platform</div>
              </Card>
              <Card style={{ width: 'min(300px, 60vw)' }}>
                <FontAwesomeIcon icon={faSearch} style={{ fontSize: 100, marginBottom: 10 }}/>
                <div style={{ lineHeight: '1.2em' }}>A single <GradientText>AI-backed</GradientText> lighting-fast search for everything across your platform for your entire organization</div>
              </Card>
              <Card style={{ width: 'min(300px, 60vw)' }}>
                <FontAwesomeIcon icon={faQuestionCircle} style={{ fontSize: 100, marginBottom: 10 }}/>
                <div style={{ lineHeight: '1.2em' }}>US-based support for you and all your users included, with a 6 hour SLA for urgent requests (1 hour for incidents and outages)</div>
              </Card>
            </div>
          </div>
        </FullPageCentered>
      </SnapSection>
      <SnapSection>
        <FullPageCentered style={{ flexDirection: 'column', background: 'rgba(255, 255, 255, 0.02)', position: 'relative' }}>
          <JumboText style={{ letterSpacing: 0.5 }}>Pricing</JumboText>
          <div style={{ height: 20 }}/>
          <Card style={{ width: 'min(300px, 65vw)', userSelect: 'none' }}>
            <JumboText style={{ fontSize: 98 }}>
              <GradientText style={{ letterSpacing: 1 }}>
                $19.99
              </GradientText>
            </JumboText>
            <JumboText style={{ fontSize: 20 }}>per user / month</JumboText>
            <JumboText style={{ fontSize: 12.5, lineHeight: '15px', marginTop: 5 }}>Billed monthly, includes all features, no minimums. Crazy simple, right?</JumboText>
          </Card>
          <div style={{ height: 20 }}/>
          <Button
            style={{ width: 'calc(min(300px, 65vw) + 50px)', height: 40, fontSize: 19 }}
            label="Get Started with a 3 Week Free Trial"
            onClick={() => {
              window.parent.postMessage('login', '*');
            }}
          />
          <div style={{ marginTop: 10, fontSize: 12.5, fontFamily: 'hiyllo', userSelect: 'none' }}>
            <GradientText>
              No credit card needed, no commitment, no strings attached.
            </GradientText>
          </div>
          <div style={{ marginTop: 10, fontSize: 15, fontFamily: 'hiyllo', maxWidth: 400, textAlign: 'center', userSelect: 'none', lineHeight: '17.5px' }}>
            <FontAwesomeIcon icon={faRocket}/> Startups and non-profits are eligible for discounts of up to 25%, email <span style={{ userSelect: 'text' }}>sales@hiyllo.io</span> for more information.
          </div>
          <div style={{ textAlign: 'center', padding: 20, bottom: 0, fontSize: 12, position: 'absolute' }}>
            &copy; Hiyllo, Inc 2024 - All Rights Reserved - <a style={{ color: 'white' }} target="_blank" href="https://www.hiyllo.work/work/legal/privacy-policy.pdf" rel="noreferrer">Privacy Policy</a>
          </div>
        </FullPageCentered>
      </SnapSection>
    </RootPage>
  );
}
// @ts-expect-error zzz
export const GradientText = styled('span', ({ $theme }) => ({
  background: $theme.buttonBackground,
  backgroundClip: 'text',
  color: 'transparent',
  WebkitBackgroundClip: 'text'
}));

const Card = styled('div', ({ $theme }) => ({
  background: $theme.background2,
  padding: 20,
  paddingLeft: 40,
  paddingRight: 40,
  borderRadius: 10,
  boxShadow: 'rgba(0,0,0,0.75) 0px 0px 50px -5px',
  textAlign: 'center'
}));

export { App as Landing };
