/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
export type ParamsType = {
    organizationUUID: string;
    stripePaymentMethodId: string;
};
export type ResponseType = void;
export const Endpoint = 'organizations/save-payment-method';
export const Method = 'POST';

export interface Plug {
    params: ParamsType
    response: ResponseType
    method: typeof Method
    endpoint: typeof Endpoint
};export const paramsSchema = {"type":"object","properties":{"organizationUUID":{"type":"string"},"stripePaymentMethodId":{"type":"string"}},"required":["organizationUUID","stripePaymentMethodId"],"$schema":"http://json-schema.org/draft-07/schema#"}