/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
export interface TenantData {
    tenantId: string;
    lastActive: Date;
    activeDays: string[]; // DDMMYYYY
    activeMonths: string[]; // MMYYYY
}

export type ParamsType = {
    tenantId: string;
};
export type ResponseType = {
    data: TenantData;
};
export const Endpoint = 'platforms/get-stardate-data';
export const Method = 'GET';

export interface Plug {
    params: ParamsType
    response: ResponseType
    method: typeof Method
    endpoint: typeof Endpoint
};export const paramsSchema = {"type":"object","properties":{"tenantId":{"type":"string"}},"required":["tenantId"],"$schema":"http://json-schema.org/draft-07/schema#"}