import { styled } from '@hiyllo/ux/styled';

export const PageContainer = styled('div', ({ $theme }) => ({
  background: $theme.background1,
  padding: 40,
  height: 'calc(100% - 80px)',
  width: 'calc(100% - 80px)',
  color: 'white'
}));

export const FlexCenter = styled('div', {
  height: '100%',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column'
});

export const Header = styled('div', {
  fontSize: 34,
  marginBottom: 7.5,
  fontWeight: 'bold',
  fontFamily: 'hiyllo'
});

export const Label = styled('div', {
  fontSize: 14,
  marginBottom: 7.5
});
