import React from 'react';
import { seamlessClient } from '../../seamless';

import * as GetOrganizationsBP from '../../blueprints/organizations/get-all-organizations-admin';
import * as GetPlatformsForOrganizationsBP from '../../blueprints/platforms/get-platforms-for-organizations';
import {
  ErrorView, GrowLoading, LoadingSpinner
} from '../ux/loading-spinner';
import { PageContainer } from '../common';
import { Organization } from '../../types/organization';
import { Platform } from '../../types/platforms';
import { styled } from '@hiyllo/ux/styled';
import { SubHeader } from '../ux/typography';
import { useNavigate } from 'react-router-dom';

const PlatformListItemContainer = styled('div', ({ $theme }) => ({
  background: $theme.background3,
  borderRadius: 15,
  padding: 15,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 20,
  cursor: 'pointer'
}));

const PlatformListItem = React.memo(function PlatformListItem (props: {platform: Platform}): JSX.Element {
  const navigate = useNavigate();

  return (
    <PlatformListItemContainer
      onClick={() => {
        navigate(`/organization/${props.platform.organizationUUID}/platform/${props.platform.uuid}`);
      }}
    >
      <div style={{ height: 30 }}>
        <img src="/work/icon/white-outline.png" style={{
          height: 30,
          width: 'auto',
          paddingLeft: 5
        }}/>
      </div>
      <SubHeader>
        {props.platform.name}
      </SubHeader>
      <div>
        {props.platform.status}
      </div>
      <div>
        Current Billable Users: {props.platform.usage?.billableUsersCurrent}
      </div>
      <div>
        Storage Usage: {props.platform.usage?.storageUsageCurrent} (Peak: {props.platform.usage?.storageUsageGBPeak})
      </div>
    </PlatformListItemContainer>
  );
});

const OrganizationCard = React.memo(function OrganizationCard (props: {organization: Organization}): JSX.Element | null {
  const getPlatformsForOrganizations = seamlessClient.useSeamlessQuery<GetPlatformsForOrganizationsBP.Plug>(GetPlatformsForOrganizationsBP, { organizationUUID: props.organization.uuid });

  if (getPlatformsForOrganizations.data?.platforms.length === 0) {
    return null;
  }

  return (
    <div>
      {getPlatformsForOrganizations.isError
        ? <div>{getPlatformsForOrganizations.error.description}</div>
        : getPlatformsForOrganizations.isLoading
          ? <LoadingSpinner/>
          : <div style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 15
          }}>
            {getPlatformsForOrganizations.data.platforms.map(platform => (
              <PlatformListItem platform={platform} key={platform.uuid}/>
            ))}
          </div>
      }
    </div>
  );
});

export const Admin = (): JSX.Element => {
  const organizationsQuery = seamlessClient.useSeamlessQuery<GetOrganizationsBP.Plug>(GetOrganizationsBP, null);
  const navigate = useNavigate();

  if (organizationsQuery.isLoading) {
    return <GrowLoading/>;
  }

  if (organizationsQuery.isError) {
    return <ErrorView error={organizationsQuery.error}/>;
  }

  const { organizations } = organizationsQuery.data;

  return (
    <PageContainer>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 15
      }}>
        {organizations.map(organization => (
          <OrganizationCard organization={organization} key={organization.uuid}/>
        ))}
      </div>
    </PageContainer>
  );
};
