/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
export type ParamsType = {
    platformUUID: string;
    restricted: boolean;
};
export type ResponseType = void;
export const Endpoint = 'platforms/admin/set-platform-restricted';
export const Method = 'POST';

export interface Plug {
    params: ParamsType
    response: ResponseType
    method: typeof Method
    endpoint: typeof Endpoint
};export const paramsSchema = {"type":"object","properties":{"platformUUID":{"type":"string"},"restricted":{"type":"boolean"}},"required":["platformUUID","restricted"],"$schema":"http://json-schema.org/draft-07/schema#"}