/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { Organization } from "../../types/organization";

export type ParamsType = null;
export type ResponseType = {
    organizations: Organization[]
};
export const Endpoint = 'organizations/get-all-organizations-admin';
export const Method = 'GET';

export interface Plug {
    params: ParamsType
    response: ResponseType
    method: typeof Method
    endpoint: typeof Endpoint
};export const paramsSchema = {"type":"null","$schema":"http://json-schema.org/draft-07/schema#"}