/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { Organization } from "../../types/organization";

export type ParamsType = {
    tenantId: string;
};
export type ResponseType = {
    taken: boolean;
};
export const Endpoint = 'organizations/check-tenant-id';
export const Method = 'POST';

export interface Plug {
    params: ParamsType
    response: ResponseType
    method: typeof Method
    endpoint: typeof Endpoint
};export const paramsSchema = {"type":"object","properties":{"tenantId":{"type":"string"}},"required":["tenantId"],"$schema":"http://json-schema.org/draft-07/schema#"}