import { motion, useScroll, useTransform } from 'framer-motion';
import React from 'react';

export const EffortlesslyConnected = React.memo(function EffortlesslyConnected(): JSX.Element {
    const ref = React.useRef(null);
    const { scrollYProgress } = useScroll({
      target: ref,
      offset: ['start end', 'end start']
    });
    const translateX1 = useTransform(scrollYProgress, [0.1, 1], ['75', '-400%']);
    const translateX2 = useTransform(scrollYProgress, [0.3, 1], ['150%', '-250%']);

    return (
        <div style={{ padding: 40, width: 'calc(100% - 80px)' }} ref={ref}>
          <motion.div style={{ fontSize: 600, translateX: translateX1 }}>
            Effortlessly
          </motion.div>
          <motion.div style={{ fontSize: 600, translateX: translateX2 }}>
            Connected
          </motion.div>
        </div>
    );
});
