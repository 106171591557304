import React from 'react';
import { seamlessClient } from '../../seamless';
import { LoadingPage } from '@hiyllo/ux/standard-loader';

import * as RequestLoginBP from '../../blueprints/auth/request-login';
import * as ProcessLoginBP from '../../blueprints/auth/process-login';

export const HiylloAuthSSO = React.memo(function HiylloAuthSSO (): JSX.Element {
  const requestLoginMutation = seamlessClient.useSeamlessMutation<RequestLoginBP.Plug>(RequestLoginBP);
  const processLoginMutation = seamlessClient.useSeamlessMutation<ProcessLoginBP.Plug>(ProcessLoginBP);

  React.useEffect(() => {
    const usp = new window.URLSearchParams(window.location.search);
    const params = {
      SAMLResponse: usp.get('SAMLResponse') as string,
      RelayState: usp.get('RelayState') as string
    };

    if (window.location.pathname === '/sso' && params.SAMLResponse != null) {
      void processLoginMutation.call({
        SAMLResponse: params.SAMLResponse,
        RelayState: params.RelayState
      }).then(({ token, isEmployee }) => {
        if (isEmployee) {
          window.sessionStorage.employee = '1';
        }
        window.sessionStorage.setItem('token', token);
        const path = decodeURIComponent(params.RelayState);
        window.history.pushState({}, '', path === '/sso' ? '/' : path);
        window.location.reload();
      });
    } else {
      if (window.sessionStorage.token == null) {
        void requestLoginMutation.call(null).then(({ href }) => {
          const url = new URL(href);
          url.searchParams.set('RelayState', encodeURIComponent(window.location.pathname + window.location.search));
          window.location.href = url.toString();
        });
      }
    }
  }, []);

  return (
    <div style={{ height: '100vh' }}>
    <LoadingPage/>
    </div>
  );
});
