/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { UsageType } from "./usage";

export enum PlatformKind {
    workspace = 'workspace_basic',
    workspace_with_video = 'workspace_with_video',
    workspace_pro = 'workspace_pro',
}

export enum PlatformStatusEnum {
    initializing = 'initializing',
    active = 'active',
    restricted = 'restricted',
    terminated = 'terminated'
}

export interface Platform {
    kind: PlatformKind,
    organizationUUID: string,
    name: string,
    tenantUUID: string,
    tenantId: string,
    subscriptionUUID: string;
    usage: null | UsageType;
    usageLastSynced: Date;
    uuid: string;
    status: PlatformStatusEnum;
    v: 2;
}