import React from 'react';
import {
  BrowserRouter, Navigate, Route, Routes
} from 'react-router-dom';
import { Home } from './views/home';
import { PlatformView } from './views/platform-view';
import { FlexCenter, PageContainer } from './common';
import { HiylloAuthSSO } from './views/employee-sso';
import { Admin } from './views/admin';
import { Subscribe } from './views/subscribe';
import { Landing } from './views/landing';
import { PrivacyPolicy } from './views/privacy-policy';
import { LandingSpace } from './views/landing-space';

const Error404 = React.memo(function Error404 (): JSX.Element {
  return (
    <PageContainer>
      <FlexCenter>
        <div>Error 404</div>
      </FlexCenter>
    </PageContainer>
  );
});

export const Router = (): JSX.Element => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={window.sessionStorage.employee === '1' ? <Admin/> : <Home/>}/>
        <Route path="/create-platform/:kind" element={<Subscribe/>}/>
        <Route path="/create-platform" element={<Subscribe/>}/>
        <Route path="/subscribe" element={<Subscribe/>}/>
        <Route path="/organization/:organizationUUID/platform/:platformUUID" element={<PlatformView/>}/>
        <Route path="/login" element={<Navigate to="/"/>}/>
        <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
        <Route path="*" element={<Error404/>}/>
      </Routes>
    </BrowserRouter>
  );
};

window.addEventListener('message', (evt) => {
  if (evt.data === 'login') {
    window.location.href = 'https://www.hiyllo.work/login';
  }
});

export const LoggedOutRouter = (): JSX.Element => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing/>}/>
        <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
        <Route path="*" element={<HiylloAuthSSO/>}/>
      </Routes>
    </BrowserRouter>
  );
};
