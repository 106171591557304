/* eslint-disable @typescript-eslint/no-misused-promises */
import { CircleButton } from '@hiyllo/ux/circle-button';
import { Input } from '@hiyllo/ux/input';
import { styled } from '@hiyllo/ux/styled';
import React from 'react';
import { PromotionCodeType } from '../../types/promotion-code';
import { seamlessClient } from '../../seamless';
import * as GetPromotionBP from '../../blueprints/promotions/get-promotion';
import * as CreateOrganizationBP from '../../blueprints/organizations/create-organization';
import * as CheckTenantIdBP from '../../blueprints/organizations/check-tenant-id';
import moment from 'moment';
import {
  AddressElement,
  PaymentElement, useElements, useStripe
} from '@stripe/react-stripe-js';
import { Button } from '@hiyllo/ux/button';
import { PlatformKind } from '../../types/platforms';
import { WorkspaceForOrganizations } from '../../consts/workspace-for-organizations';
import { BillingRate, ServiceRate } from '../../types/subscription';
import { SeamlessError } from '@seamlessjs/core/main';

export const GradientText = styled('div', ({ $theme }) => ({
  background: $theme.buttonBackground,
  backgroundClip: 'text',
  color: 'transparent',
  WebkitBackgroundClip: 'text'
}));

const SubscribePaneElem = styled('div', ({ $theme }) => ({
  paddingTop: 100,
  paddingBottom: 100,
  marginLeft: '10vw',
  marginRight: '10vw',
  minHeight: 'calc(100vh - 200px)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column'
}));

const SPOuter = styled('div', ({ $theme }) => ({
  height: '100vh',
  width: '100vw',
  overflowY: 'auto',
  background: $theme.background1
}));

const SubscribePane = React.memo(function SubscribePane (props: React.PropsWithChildren): JSX.Element {
  return (
    <SPOuter>
      <SubscribePaneElem>
        {props.children}
      </SubscribePaneElem>
    </SPOuter>
  );
});

const IS_MOBILE = window.innerHeight > window.innerWidth || window.innerWidth < 600;

const JumboHeader = styled('div', {
  fontFamily: '"HiylloRegular", sans-serif',
  fontSize: IS_MOBILE ? 42 : 75,
  textAlign: 'center',
  maxWidth: '80vw',
  marginBottom: IS_MOBILE ? 10 : 0
});

const Header = styled('div', {
  fontFamily: '"HiylloRegular", sans-serif',
  fontSize: IS_MOBILE ? 30 : 48,
  textAlign: 'center',
  maxWidth: '80vw'
});

const Row = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 10
});

const InputElement = styled('input', ({ $theme }) => ({
  outline: 'none',
  border: 'none',
  colorScheme: $theme.colorScheme,
  width: 0,
  flexGrow: 1,
  display: 'inline-block',
  fontSize: 'inherit',
  background: 'transparent',
  color: $theme.foreground,
  resize: 'none',
  padding: 0,
  fontFamily: 'inherit',
  textAlign: 'right'
}));

const ContainerElement = styled<'div', { fullWidth?: boolean, fontSize?: number }>('div', ({ fullWidth, fontSize }) => ({
  width: fullWidth === true ? '100%' : 'min(300px, calc(100% - 20px))',
  fontSize: fontSize ?? 14,
  fontFamily: '"Work Sans", sans-serif'
}));

const InputContainer = styled('div', ({ $theme }) => ({
  background: $theme.midground,
  borderRadius: 10,
  padding: '0.8em',
  display: 'flex',
  flexDirection: 'row',
  color: $theme.foreground
}));

function usePromotion (code: string | null): PromotionCodeType | null {
  const [promotion, setPromotion] = React.useState<PromotionCodeType | null>(null);
  const getPromotionMutation = seamlessClient.useSeamlessMutation<GetPromotionBP.Plug>(GetPromotionBP);

  React.useEffect(() => {
    if (code != null) {
      void getPromotionMutation.call({ code }).then((res) => {
        setPromotion(res.promotion);
      });
    }
  }, [code]);

  return promotion;
}

export const Subscribe = React.memo(function Subscribe (): JSX.Element {
  const orgNameInputRef = React.useRef<HTMLInputElement>(null);
  const [orgName, setOrgName] = React.useState<string | null>(null);
  const onSaveOrgName = React.useCallback(() => {
    if (orgNameInputRef.current != null) {
      if (orgNameInputRef.current.value.length < 3) {
        return alert('Enter a name for your organization');
      }

      setOrgName(orgNameInputRef.current.value);
    }
  }, []);

  const checkTenantIdMutation = seamlessClient.useSeamlessMutation<CheckTenantIdBP.Plug>(CheckTenantIdBP);
  const tenantInputRef = React.useRef<HTMLInputElement>(null);
  const [tenantId, setTenantId] = React.useState<string | null>(null);
  const onSaveTenantId = React.useCallback(() => {
    if (tenantInputRef.current != null) {
      const tenantId = tenantInputRef.current.value.trim().toLowerCase();

      void checkTenantIdMutation.call({ tenantId }).then((res) => {
        if (res.taken) {
          alert('Sorry, that URL is already taken. Please try another one.');
        } else {
          setTenantId(tenantId);
        }
      });
    }
  }, [checkTenantIdMutation]);

  const [planReviewed, setPlanReviewed] = React.useState<boolean>(false);
  const [showExtendedDetails, setShowExtendedDetails] = React.useState<boolean>(false);

  const onAcceptPlan = React.useCallback(() => {
    setPlanReviewed(true);
  }, []);

  const elements = useElements();
  const stripe = useStripe();
  const [stripePaymentMethodId, setStripePaymentMethodId] = React.useState<string | null>(null);
  const onSavePaymentDetails = React.useCallback(async () => {
    if (elements == null || stripe == null) {
      return console.warn({
        elements,
        stripe
      });
    }

    const { error: submitError } = await elements.submit();
    if (submitError != null) {
      return alert(submitError.message ?? 'There was an issue with your payment details');
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({ elements });

    if (error != null) {
      return alert(error.message ?? 'There was an issue with your payment details');
    }

    setStripePaymentMethodId(paymentMethod.id);
  }, [elements, stripe]);

  const promotionCode = new window.URLSearchParams(window.location.search).get('promotion') ?? 'freetrial';
  const promotion = usePromotion(promotionCode);

  const createOrganizationMutation = seamlessClient.useSeamlessMutation<CreateOrganizationBP.Plug>(CreateOrganizationBP);

  const { billingRate } = WorkspaceForOrganizations.workspace_with_video as { billingRate: BillingRate, serviceRate: ServiceRate };
  const daysTillNextRenewal = Math.max(0, 28 - moment().date());
  const absBaseCost = (billingRate.costPerUser * billingRate.minimumUsers);

  const anticipatedCost = daysTillNextRenewal === 0 ? absBaseCost : ((absBaseCost / 28) * daysTillNextRenewal);

  const onSubmit = React.useCallback(() => {
    if (stripe == null || elements == null || orgName == null || tenantId == null) {
      return console.warn({
        stripe,
        elements,
        orgName,
        stripePaymentMethodId,
        tenantId
      });
    }

    void createOrganizationMutation.call({
      name: orgName,
      stripePaymentMethodId,
      kind: PlatformKind.workspace_with_video,
      anticipatedCost,
      tenantId,
      promotionCode,
      affiliate: window.localStorage.getItem('affiliate') ?? null
    }).then((res) => {
      window.location.href = res.path;
    }).catch((err) => {
      alert(((err as SeamlessError).message ?? 'There was an issue creating your organization') + '. Please contact sales@hiyllo.io for help.');
    });
  }, [stripe, elements, orgName, stripePaymentMethodId, tenantId, createOrganizationMutation, anticipatedCost, promotionCode]);

  if (orgName == null) {
    return (
      <SubscribePane>
        <Header>
          <GradientText>
            What&apos;s the legal name of your organization?
          </GradientText>
        </Header>
        <div style={{ height: 10 }}/>
        <div style={{
          fontFamily: '"HiylloRegular", sans-serif',
          fontSize: 20,
          textAlign: 'center',
          color: 'white'
        }}>
          This name is for our records and billing, you can show a different name to your users later.
        </div>
        <div style={{
          fontFamily: '"HiylloRegular", sans-serif',
          fontSize: 20,
          textAlign: 'center',
          color: 'white'
        }}>
          No corporate entity yet? No problem, just put your own name here.
        </div>
        <div style={{ padding: 20 }}/>
        <Row>
          <div style={{ width: 300 }}>
            <Input
              placeholder='ACME Inc.'
              fullWidth
              onSubmit={onSaveOrgName}
              ref={orgNameInputRef}
            />
          </div>
          <CircleButton
            onClick={onSaveOrgName}
            size={40}
          />
        </Row>
      </SubscribePane>
    );
  }

  if (tenantId == null) {
    return (
      <SubscribePane>
        <Header>
          <GradientText>
            Create your hiyllo.work URL
          </GradientText>
        </Header>
        <div style={{ height: 10 }}/>
        <div style={{
          fontFamily: '"HiylloRegular", sans-serif',
          fontSize: 20,
          textAlign: 'center',
          color: 'white'
        }}>
          This is the URL people will visit to access your workspace
        </div>
        <div style={{ padding: 10 }}/>
        <Row>
          <div style={{ width: 300 }}>
            <ContainerElement>
              <label>
                <InputContainer>
                  <InputElement
                    maxLength={20}
                    onKeyDown={(evt: React.KeyboardEvent) => {
                      if (evt.key === 'Enter') {
                        onSaveTenantId();
                      }
                    }}
                    _ref={tenantInputRef}
                    autoFocus
                  />.hiyllo.work
                </InputContainer>
              </label>
            </ContainerElement>
          </div>
          <CircleButton
            onClick={onSaveTenantId}
            size={40}
            isLoading={checkTenantIdMutation.isLoading}
          />
        </Row>
      </SubscribePane>
    );
  }

  if (promotion == null) {
    return <div>Loading</div>;
  }

  if (!planReviewed) {
    if (showExtendedDetails) {
      return (
        <SubscribePane>
          <Header>
            <GradientText>
              You like all the details, nice.
            </GradientText>
          </Header>
          <div style={{ height: 40 }}/>
          <div style={{
            color: 'white',
            fontFamily: '"HiylloRegular", sans-serif',
            width: '100%'
          }}>
            <Row style={{ gap: 40, flexWrap: 'wrap' }}>
              <div>
                <b>You are receiving a</b>
                <GradientText style={{ fontSize: 30 }}>
                  {promotion.grant.duration.weeks} week free trial
                </GradientText>
              </div>
              <div>
                <b>Your free trial will expire:</b>
                <GradientText style={{ fontSize: 30 }}>
                  {moment().add(promotion.grant.duration.weeks, 'weeks').format('MMMM Do, YYYY')}
                </GradientText>
              </div>
            </Row>
            <div style={{ height: 20 }}/>
            <div>We&apos;ll give you a heads up via email when you have 48 hours left to cancel.</div>
            <div style={{ height: 5 }}/>
            <div>If you like what you see and your plan activates, the following terms kick in:</div>
            <div style={{ height: 20 }}/>
            <Row style={{ gap: 40, flexWrap: 'wrap' }}>
              <div>
                <b>Price per user / month:</b>
                <GradientText style={{ fontSize: 30 }}>
                  $19.99
                </GradientText>
              </div>
              <div>
                <b>Included storage per user:</b>
                <GradientText style={{ fontSize: 30 }}>
                  10gb*
                </GradientText>
              </div>
            </Row>
            <div style={{ height: 20 }}/>
            <div>Once you are on a paid plan, you get the following SLA:</div>
            <div style={{ height: 20 }}/>
            <Row style={{ gap: 40, flexWrap: 'wrap' }}>
              <div>
                <b>Uptime:</b>
                <GradientText style={{ fontSize: 30 }}>
                  99%
                </GradientText>
              </div>
              <div>
                <b>Question Response Time</b>
                <GradientText style={{ fontSize: 30 }}>
                  16 hours
                </GradientText>
              </div>
              <div>
                <b>Urgent Response Time</b>
                <GradientText style={{ fontSize: 30 }}>
                  6 hours
                </GradientText>
              </div>
              <div>
                <b>Outage Response Time</b>
                <GradientText style={{ fontSize: 30 }}>
                  1 hour
                </GradientText>
              </div>
            </Row>
            <div style={{ height: 20 }}/>
            <div style={{ maxWidth: 600 }}>*Free trials are limited to 250gb of file storage usage. If you go over your file storage limit on the free trial, we&apos;ll email you asap and may disable uploading any more files. If you go over on a paid plan, we&apos;ll charge you at a rate of $4.99 per 100GB.</div>
            <div style={{ height: 20 }}/>
            <div style={{ maxWidth: 600 }}>Sales tax is not included on any price. Contact sales@hiyllo.io if you need help estimating sales tax.</div>
          </div>
          <div style={{ height: 40 }}/>
          <Row>

            <CircleButton
              onClick={onAcceptPlan}
              size={40}
            />
          </Row>
        </SubscribePane>
      );
    }

    return (
      <SubscribePane>
        <Header>
          <GradientText>
            Review your plan
          </GradientText>
        </Header>
        <div style={{ height: 40 }}/>
        <div style={{
          color: 'white',
          fontFamily: '"HiylloRegular", sans-serif'
        }}>
          <Row style={{ gap: 40 }}>
            <div>
              <b>Your free trial supports up to:</b>
              <GradientText style={{ fontSize: 30 }}>
                {promotion.grant.maximumSeats} users
              </GradientText>
            </div>
            <div>
              <b>Your free trial will expire:</b>
              <GradientText style={{ fontSize: 30 }}>
                {moment().add(promotion.grant.duration.weeks, 'weeks').format('MMMM Do, YYYY')}
              </GradientText>
            </div>
          </Row>
          <div style={{ height: 20 }}/>
          <div>Once your free trial ends:</div>
          <div style={{ height: 20 }}/>
          <Row style={{ gap: 40 }}>
            <div>
              <b>Price per user / month:</b>
              <GradientText style={{ fontSize: 30 }}>
              $19.99*
              </GradientText>
            </div>
            <div>
              <b>Included storage per user:</b>
              <GradientText style={{ fontSize: 30 }}>
                10gb
              </GradientText>
            </div>
          </Row>
          <div style={{ height: 40 }}/>
          <div style={{ textAlign: 'center' }}>
            <div
              onClick={() => setShowExtendedDetails(true)}
              style={{
                textAlign: 'center',
                userSelect: 'none',
                cursor: 'pointer',
                maxWidth: 400,
                display: 'inline-block'
              }}>*Sales tax (if applicable) not included. For details on SLAs, limits, overage costs, etc, click here</div>
          </div>
        </div>
        <div style={{ height: 40 }}/>
        <Row>

          <CircleButton
            onClick={onAcceptPlan}
            size={40}
          />
        </Row>
      </SubscribePane>
    );
  }

  // if (stripePaymentMethodId == null) {
  //   return (
  //     <SubscribePane>
  //       <JumboHeader>
  //         <GradientText>
  //             Step 4. Grab your credit card
  //         </GradientText>
  //       </JumboHeader>
  //       <Header>
  //         <GradientText>
  //           You won&apos;t be charged until your free trial ends.
  //         </GradientText>
  //       </Header>
  //       <div style={{
  //         marginTop: 10,
  //         fontFamily: '"HiylloRegular", sans-serif'
  //       }}>
  //         <GradientText>
  //           We&apos;ll email you 48 hours before your free trial ends to remind you to cancel if you don&apos;t want to be charged.
  //         </GradientText>
  //       </div>
  //       <div style={{
  //         marginTop: 10,
  //         fontFamily: '"HiylloRegular", sans-serif'
  //       }}>
  //         <GradientText>
  //           If you&apos;re interested but aren&apos;t ready to enter your credit card, email us at sales@hiyllo.io and we&apos;ll see what we can do
  //         </GradientText>
  //       </div>
  //       <div style={{ height: 20 }}/>
  //       <div>
  //         <div style={{
  //           fontSize: 12,
  //           color: '#b3b3b3',
  //           textAlign: 'left'
  //         }}>Payments powered by Stripe,<br/>Hiyllo does not store your card info</div>
  //         <div style={{ height: 20 }}/>

  //       </div>
  //       <div style={{ padding: 20 }}/>
  //       <Row>
  //       </Row>
  //     </SubscribePane>
  //   );
  // }

  return (
    <SubscribePane>
      <Header>
        <GradientText>
          Almost done.
        </GradientText>
      </Header>
      <div style={{ height: 40 }}/>
      <div style={{
        color: 'white',
        fontFamily: '"HiylloRegular", sans-serif'
      }}>
        <div style={{ fontSize: 24 }}>By signing up and clicking &quot;Activate&quot; below, you agree:</div>
        <div style={{ maxWidth: 600 }}>
          <ul style={{ fontSize: 20 }}>
            <li>You have read and understand the billing terms stated</li>
            <li>You have read and agree to the <a href="/work/legal/terms-of-service.pdf" target="_blank" style={{ color: 'white' }}>Hiyllo Work Terms of Service</a> (governs your relationship with Hiyllo)</li>
            <li>You have read and agree to the <a href="/work/legal/platform-terms-of-use.pdf" target="_blank" style={{ color: 'white' }}>Platform Terms of Use</a> (governs usage of the platform by you and your authorized users)</li>
            <li>You have read and agree to the <a href="/work/legal/privacy-policy.pdf" target="_blank" style={{ color: 'white' }}>Privacy Policy</a></li>
            <li>You have the authority to bind your Organization to these agreements</li>
            <li>You are responsible for enforcing that anyone you or your organization directly or indirectly allows onto your Hiyllo Work platform has read and agrees to the Platform Terms of Use and Privacy Policy</li>
          </ul>
        </div>
      </div>
      <div style={{ height: 40 }}/>
      <Row>

        <Button
          label="Activate"
          onClick={onSubmit}
          isLoading={createOrganizationMutation.isLoading}
        />
      </Row>
    </SubscribePane>
  );
});
