/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { PlatformKind } from "../types/platforms";
import { BillingRate, ServiceRate } from "../types/subscription";

export const WorkspaceForOrganizations: {[k in PlatformKind]: { billingRate: BillingRate, serviceRate: ServiceRate }} = {
    [PlatformKind.workspace]: {
        billingRate: {
            costPerUser: 1999,
            minimumUsers: 1,
            includedGBPerUser: 10
        },
        serviceRate: {
            capacity: 100,
            videoMeeting: "disabled",
            slaUptime: 98,
            slaResponseTime: '24 Hours for Questions, 8 Hours for Urgent Requests, 2 Hours for Incidents/Outages'
        }
    },
    [PlatformKind.workspace_with_video]: {
        billingRate: {
            costPerUser: 1999,
            minimumUsers: 1,
            includedGBPerUser: 25
        },
        serviceRate: {
            capacity: 100,
            videoMeeting: "shared",
            slaUptime: 99,
            slaResponseTime: '16 Hours for Questions, 6 Hours for Urgent Requests, 1 Hour for Incidents/Outages'
        }
    },
    [PlatformKind.workspace_pro]: {
        billingRate: {
            costPerUser: 1999,
            minimumUsers: 1,
            includedGBPerUser: 25
        },
        serviceRate: {
            capacity: 100,
            videoMeeting: "dedicated",
            slaUptime: 99.99,
            slaResponseTime: '12 Hours for Questions, 4 Hours for Urgent, 30 Mins for Incidents/Outages'
        }
    }
} as const;