/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { Platform } from "../../types/platforms";
import type { Organization } from '../../types/organization';
import { SubscriptionType } from "../../types/subscription";

export type ParamsType = {
    organizationUUID: string;
    platformUUID: string;
};
export type ResponseType = {
    platform: Platform;
    organization: Organization;
    subscription: SubscriptionType;
};
export const Endpoint = 'platforms/get-platform';
export const Method = 'GET';

export interface Plug {
    params: ParamsType
    response: ResponseType
    method: typeof Method
    endpoint: typeof Endpoint
};export const paramsSchema = {"type":"object","properties":{"organizationUUID":{"type":"string"},"platformUUID":{"type":"string"}},"required":["organizationUUID","platformUUID"],"$schema":"http://json-schema.org/draft-07/schema#"}