/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { PlatformKind } from "../../types/platforms";

export type ParamsType = {
    name: string,
    stripePaymentMethodId: string | null,
    kind: PlatformKind,
    anticipatedCost: number,
    tenantId: string,
    promotionCode: string | null,
    affiliate: string | null
};
export type ResponseType = {
    organizationUUID: string,
    platformUUID: string,
    subscriptionUUID: string,
    invoiceUUID: string,
    adminRegistrationCode: string,
    path: string
};
export const Endpoint = 'organizations/create-organization';
export const Method = 'POST';

export interface Plug {
    params: ParamsType
    response: ResponseType
    method: typeof Method
    endpoint: typeof Endpoint
};export const paramsSchema = {"type":"object","properties":{"name":{"type":"string"},"stripePaymentMethodId":{"type":["null","string"]},"kind":{"$ref":"#/definitions/PlatformKind"},"anticipatedCost":{"type":"number"},"tenantId":{"type":"string"},"promotionCode":{"type":["null","string"]},"affiliate":{"type":["null","string"]}},"required":["affiliate","anticipatedCost","kind","name","promotionCode","stripePaymentMethodId","tenantId"],"definitions":{"PlatformKind":{"enum":["workspace_basic","workspace_pro","workspace_with_video"],"type":"string"}},"$schema":"http://json-schema.org/draft-07/schema#"}