import React from 'react';

export const Header = (props: React.PropsWithChildren): JSX.Element => (
  <div style={{
    fontFamily: '"Manrope", sans-serif',
    fontSize: 38,
    fontWeight: '700'
  }}>
    {props.children}
  </div>
);

export const SubHeader = (props: React.PropsWithChildren): JSX.Element => (
  <div style={{
    fontFamily: '"Manrope", sans-serif',
    fontSize: 28,
    fontWeight: '700'
  }}>
    {props.children}
  </div>
);
